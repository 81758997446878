import React, { Component, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col, Input, Button, FormGroup, Label, Alert,
          Navbar, NavbarBrand, NavItem, NavLink, Nav, Form } from 'reactstrap';
import './App.css';
import $ from 'jquery';
import axios from 'axios';


class App extends Component {
  state = {
    questionnaires: [],
    loggedInUser: null,
    loginEmail: '',
    loginPassword: '',
    searchText: '',
    url: 'https://esg.think-plus.gr/',
    activeQair: -1,
    activeQs: -1,
    activeQg: -1,
    activeQ: -1,
    activeQuestion: null,
    section: 'Questionnaire',
    activeUser: null,
    activeU: -1,
    users: null
  };

  constructor(){
    super();
    this.saveQuestionnaire = this.saveQuestionnaire.bind(this);
    this.addNewUser = this.addNewUser.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  changeLoginEmail(text){
    this.setState({
      loginEmail: text
    });
  }

  changeLoginPassword(pass){
    this.setState({
      loginPassword: pass
    });
  }

  getUsers(){
    var getUsersPromise = new Promise((resolve, reject) => {
      axios.get(this.state.url + 'auth/users').then(response => {
        console.log(response);
        resolve(response.data);
      }).catch(e => {
        console.log(e.message);
      })
    });
    return getUsersPromise;
  }

  login(email, password){
    var url = this.state.url;
    var loginPromise = new Promise((resolve, reject) => {
      axios.post(url + 'auth/login', {email, password}).then((response) => {
        if(response.data.success){
          var u = response.data.user
          localStorage.setItem('userData', JSON.stringify(u));
          resolve(u);
        }
        else{
          reject('Wrong Email or Password');
        }
      });
    });
    return loginPromise;
  }

  async handleLogin(){
    try{
      var user = await this.login(this.state.loginEmail, this.state.loginPassword);
      this.setState({
        loggedInUser: user
      });
      var qs = await this.fetchQuestionnaires();
      this.setState({
        questionnaires: qs
      });
      try{
        var users = await this.getUsers();
        this.setState({
          users
        });
      }catch(e){
        console.log(e.message);
      }
    }catch(e){
      this.setState({errorMessage: e})
    }
  }

  fetchQuestionnaires(){
    const questionnairesPromise = new Promise((resolve) => {
      $.ajax({
        method: 'get',
        url: this.state.url + 'questionnaire/all',
        success: function(result){
            resolve(result);
        }
      });
    });
    return questionnairesPromise;
  
  }

  setQMaxImpact(mi){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].qRange = mi;
    this.setState({
      questionnaires
    });
  }

  setQTitle(mi){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].qText = mi;
    this.setState({
      questionnaires
    });
  }

  setQRange(mi){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].qMaxImpact = mi;
    this.setState({
      questionnaires
    });
  }

  setQNoImpact(mi){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].qNoImpact = mi;
    this.setState({
      questionnaires
    });
  }

  setQYesImpact(mi){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].qYesImpact = mi;
    this.setState({
      questionnaires
    });
  }

  setQYesSuggestion(mi){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].qYesSuggestion = mi;
    this.setState({
      questionnaires
    });
  }

  setQNoSuggestion(mi){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].qNoSuggestion = mi;
    this.setState({
      questionnaires
    });
  }

  setQRangeFactor(mi){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].qRangeImpact = mi;
    this.setState({
      questionnaires
    });
  }

  setQSdgs(mi){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].qSdgs = mi;
    console.log(mi);
    this.setState({
      questionnaires
    });
  }

  setQOrder(mi){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].qOrder = mi;
    this.setState({
      questionnaires
    });
  }

  setQSectorSpecific(mi){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].qSectorSpecific = mi;
    this.setState({
      questionnaires
    });
  }

  setQType(mi){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].qType = mi;
    this.setState({
      questionnaires
    });
  }

  setAText(i, at){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].answers[i].aText = at;
    this.setState({
      questionnaires
    });
  }

  setAImpact(i, at){
    var questionnaires = this.state.questionnaires;
    questionnaires[this.state.activeQair].questionSections[this.state.activeQs].questionGroups[this.state.activeQg].questions[this.state.activeQ].answers[i].aImpact = at;
    this.setState({
      questionnaires
    });
  }

  setUserEmail(t){
    var users = this.state.users;
    users[this.state.activeU].userEmail = t;
    this.setState({
      users
    });
  }

  setUserName(t){
    var users = this.state.users;
    users[this.state.activeU].userName = t;
    this.setState({
      users
    });
  }

  setUserSurname(t){
    var users = this.state.users;
    users[this.state.activeU].userSurname = t;
    this.setState({
      users
    });
  }

  setUserGroup(t){
    var users = this.state.users;
    users[this.state.activeU].userGroup = t;
    this.setState({
      users
    });
  }

  async saveQuestionnaire(){
    var questionnaire = this.state.questionnaires[0];
    try{
      var response = await axios.post(this.state.url + 'questionnaire/save', { questionnaire });
      if(response){
        this.setState({
          successMessage: 'The questionnaire has been updated successfully',
          errorMessage: null
        });
        
        this.fetchQuestionnaires();
      }
    }catch(e){
      console.log(e);
      this.state.errorMessage = e.message;
    }
    setTimeout(() => {
      this.setState({
        successMessage: '',
        errorMessage: ''
      });
    }, 3000);
  }

  async saveUser(){
    var user = this.state.activeUser;
    try{
      var response = await axios.post(this.state.url + 'auth/save-user', {user})
      if(response){
        this.setState({
          successMessage: 'User saved successfully'
        });
        var u = response.data;
        var users = this.state.users;
        users[this.state.activeU] = u;
        this.setState({users});
        setTimeout(() => {
          this.setState({
            successMessage: ''
          })
        }, 3000);
      }
    }catch(e){
      console.log(e.message);
    }
  }

  resetPassword(){
    if(window.confirm('Are you sure you want to reset the password of this user?')){
    var user = this.state.activeUser;
      axios.post(this.state.url + 'auth/reset-password', {email: user.userEmail}).then(response => {
        alert('Password was reset.Tell the user to check their email');
      });
    }
  }

  addNewUser(){
    var users = this.state.users;
    users.push({
      userId: 0,
      userEmail: '',
      userName: '',
      userSurname: '',
      userGroup: ''
    });
    this.setState({users, activeU: users.length-1, activeUser: this.state.users[users.length-1]});
  }

  render(){
    return (
      <Container>
        <Navbar color="faded" light expand="md">
          <NavbarBrand href="/" className="mr-auto">ESG Rating Admin</NavbarBrand>
          <Nav className="mr-auto" navbar>
            <NavItem>
                <NavLink href="#" onClick={() => { this.setState({section: 'Questionnaire'}) }}>Questionnaire</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={() => { this.setState({section: 'Users'}) }}>Users</NavLink>
            </NavItem>
          </Nav>
        </Navbar>
        {
          !this.state.loggedInUser && (
            <Row style={{marginTop: '100px'}}>
              <Col md="6" className="ml-auto mr-auto">
                <h3>Login to the Admin Panel</h3>
                <FormGroup>
                  <Input type="text" onChange={(e) => { this.changeLoginEmail(e.target.value) }} placeholder="Email" />
                </FormGroup>
                <FormGroup>
                  <Input type="password" onChange={(e) => { this.changeLoginPassword(e.target.value) }} placeholder="Password" />
                </FormGroup>
                <FormGroup>
                  <Button color="primary" className="btn-block" onClick={() => {this.handleLogin()}}>Login</Button>
                </FormGroup>
              </Col>
            </Row>
          )
        }
        {
          this.state.loggedInUser && this.state.loggedInUser.userGroup === 'admin' && (
            <Fragment>
              {
              this.state.section === 'Questionnaire' && 
              <Fragment>
              <Row>
              <Col md="9" className="bg-white">
                {
                  this.state.successMessage && (
                    <Alert color="success">{this.state.successMessage}</Alert>
                  )
                }
                {
                  this.state.errorMessage && (
                    <Alert color="danger">{this.state.errorMessage}</Alert>
                  )
                }
              </Col>
              <Col md="3">
                <Button className="ml-auto" onClick={this.saveQuestionnaire}>Save Changes</Button>
              </Col>
            </Row>
            <Row>
              <Col md="7">
                <h1>Questionnaires</h1>
                {
                  this.state.questionnaires.map((qair, i) => {
                    return (
                      <Fragment key={i}>
                        <h2>{i + 1} - {qair.qairTitle}</h2>
                        <input type="text" className="form-control" onChange={(e) => {
                          this.setState({
                            searchText: e.target.value
                          });
                        }} placeholder="Search"/>
                        {
                          qair.questionSections.map((qsection, j) => {
                            return (
                              <Fragment key={j}>
                                <h3 style={{ color: qsection.qsColor }}>{j + 1} - {qsection.qsTitle}</h3>
                                {
                                  qsection.questionGroups.filter((qg) => {
                                    for(var v = 0; v < qg.questions.length; v++){
                                      if(qg.questions[v].qText.includes(this.state.searchText)){
                                        return true;
                                      }
                                    }
                                    return false;
                                  }).map((qgroup, k) => {
                                    return (
                                      <Fragment key={k}>
                                        <h4>{k + 1} - {qgroup.qgTitle}</h4>
                                        {
                                          qgroup.questions.map((q, l) => {
                                            if(q.qText.includes(this.state.searchText)){
                                              return <p key={l}><a  onClick={() => {
                                                this.setState({
                                                  activeQuestion: null
                                                });
                                                setTimeout(() => {
                                                  this.setState({
                                                    activeQair: i,
                                                    activeQs: j,
                                                    activeQg: k,
                                                    activeQ: l,
                                                    activeQuestion: q
                                                  });
                                                }, 300);
                                                console.log(this.state);
                                              }}>{l + 1} - {q.qText}</a></p>
                                            }
                                            else{
                                              return <Fragment />
                                            }
                                          })
                                        }
                                      </Fragment>
                                    )
                                  })
                                }
                              </Fragment>
                            )
                          })
                        }
                        <hr />
                      </Fragment>
                    ) 
                  })
                }
              </Col>
              <Col md="5">
                {
                  this.state.activeQuestion && (
                    <Fragment>
                      <h1>Edit Question</h1>
                      <FormGroup>
                        <Label>Question Text</Label>
                        <input type="text" className="form-control" defaultValue={this.state.activeQuestion.qText} onChange={(e) => { this.setQTitle(e.target.value) }} placeholder="Question Text"/>
                      </FormGroup>
                      <FormGroup>
                        <Label>Question Type</Label>
                        <select className="form-control" defaultValue={this.state.activeQuestion.qType} onChange={(e) => { this.setQType(e.target.value) }}>
                          <option value="yesno" >Yes / No</option>
                          <option value="options" >Options</option>
                          <option value="multipleOptions" >MultipleOptions</option>
                          <option value="text" >Text</option>
                          <option value="range" >Range</option>
                          <option value="percentage" >Percentage</option>
                        </select>
                      </FormGroup>
                      <FormGroup>
                        <Label>Question Order</Label>
                        <input className="form-control" type="number" defaultValue={this.state.activeQuestion.qOrder} placeholder="Question Order into group" onChange={(e) => {this.setQOrder(e.target.value)}} />
                      </FormGroup>
                      <FormGroup>
                        <Label>Question SDGs</Label>
                        <input className="form-control" type="text" defaultValue={this.state.activeQuestion.qSdgs} onChange={(e) => { this.setQSdgs(e.target.value) }} placeholder="SDGs covered"/>
                      </FormGroup>
                      <FormGroup>
                        <Label>Suggestion if the answer is Yes</Label>
                        <input className="form-control" type="text" defaultValue={this.state.activeQuestion.qYesSuggestion} onChange={(e) => { this.setQYesSuggestion(e.target.value) }}
                          placeholder="Suggestion if Yes, or percentage greater or equal than 50%" />
                      </FormGroup>
                      <FormGroup>
                        <Label>Suggestion if the answer is No</Label>
                        <input className="form-control" type="text" defaultValue={this.state.activeQuestion.qNoSuggestion} onChange={(e) => { this.setQNoSuggestion(e.target.value) }}
                          placeholder="Suggestion if No, or percentage less than 50%" />
                      </FormGroup>
                      {
                        this.state.activeQuestion.qType === "range" && (
                          <FormGroup>
                            <Label>Range (ex. 0,30 for range from 0 to 30)</Label>
                            <input className="form-control" type="text" defaultValue={this.state.activeQuestion.qRange} placeholder="Range (0,30 for range from 0 to 30)"
                              onChange={(e) => {this.setQRange(e.target.value)}} />
                          </FormGroup>
                        )
                      }
                      {
                        (this.state.activeQuestion.qType === "range" || this.state.activeQuestion.qType === "percentage") && (
                          <FormGroup>
                            <Label>Range factor (for range and percentage questions, the number to which we multiply the selection to calculate the score ex. 0.1 for percentage with scores 0-10)</Label>
                            <input className="form-control" type="text" defaultValue={this.state.activeQuestion.qRangeImpact} placeholder="Range Factor"
                              onChange={(e) => {this.setQRangeFactor(e.target.value)}} />
                          </FormGroup>
                        )
                      }
                      {
                        (this.state.activeQuestion.qType === "text" || this.state.activeQuestion.qType === "yesno") && (
                          <FormGroup>
                            <Label>Yes Impact</Label>
                            <input className="form-control" type="text" defaultValue={this.state.activeQuestion.qYesImpact} placeholder="Yes Impact to the score"
                              onChange={(e) => {this.setQYesImpact(e.target.value)}} />
                          </FormGroup>
                        )
                      }
                      {
                        (this.state.activeQuestion.qType === "text" || this.state.activeQuestion.qType === "yesno") && (
                          <FormGroup>
                            <Label>No Impact</Label>
                            <input className="form-control" type="text" defaultValue={this.state.activeQuestion.qNoImpact} placeholder="No Impact to the score"
                              onChange={(e) => {this.setQNoImpact(e.target.value)}} />
                          </FormGroup>
                        )
                      }
                        <FormGroup>
                          <Label>Max Impact</Label>
                          <input className="form-control" type="text" defaultValue={this.state.activeQuestion.qMaxImpact} placeholder="Max Impact to the score"
                            onChange={(e) => {this.setQMaxImpact(e.target.value)}} />
                        </FormGroup>
                        <FormGroup>
                          <Label>Sector Specific</Label>
                          <select className="form-control" defaultValue={this.state.activeQuestion.qSectorSpecific ? this.state.activeQuestion.qSectorSpecific : ''} onChange={(e) => { this.setQSectorSpecific(e.target.value) }}>
                            <option value="">Choose Category if needed</option>
                            <option value="AIRPORTS" >AIRPORTS</option>
                            <option value="PORTS" >PORTS</option>
                            <option value="MOTORWAYS" >MOTORWAYS</option>
                            <option value="MARINAS" >MARINAS</option>
                          </select>
                        </FormGroup>
                        {
                          (this.state.activeQuestion.qType === 'options' || this.state.activeQuestion.qType === 'multipleOptions') && (
                            <Fragment>
                              <h5>Answers</h5>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Answer Text</th>
                                    <th>Impact</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    this.state.activeQuestion.answers.map((ans, h) => {
                                      return <tr key={h}>
                                        <td><input defaultValue={ans.aText} className="form-control" onChange={(e) => {
                                          this.setAText(h, e.target.value)
                                        }} /></td>
                                        <td><input type="number" step="any" defaultValue={ans.aImpact} className="form-control" onChange={(e) => {
                                          this.setAImpact(h, e.target.value);
                                        }} /></td>
                                      </tr>
                                    })
                                  }
                                </tbody>
                              </table>
                            </Fragment>
                          )
                        }
                    </Fragment>
                  )
                }
              </Col>
            </Row>
            </Fragment>
            }
            {
              this.state.section === 'Users' && (
                <Fragment>
                  <Row>
                    <Col md="6">
                      <Button className="float-right" color="success" onClick={this.addNewUser}>Add User</Button>
                      <table className="table table=striped">
                        <thead>
                          <tr>
                            <th>User Email</th>
                            <th>User Name</th>
                            <th>User Role</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.users.map((u, i) => {
                              return <tr key={i}>
                                <td>{u.userEmail}</td>
                                <td>{u.userName + ' ' + u.userSurname}</td>
                                <td>{u.userGroup}</td>
                                <td>
                                  <Button color="primary" onClick={() => {
                                    this.setState({activeUser: u, activeU: i});
                                  }}>Edit</Button>
                                </td>
                              </tr>
                            })
                          }
                        </tbody>
                      </table>
                    </Col>
                    <Col md="6">
                      {
                        this.state.activeUser && (
                          <Fragment>
                            <FormGroup>
                              <Button className="float-right" color="warning" onClick={this.resetPassword}>Reset Password</Button>
                            </FormGroup>
                            <FormGroup>
                              <Label>User Email</Label>
                              <input type="email" defaultValue={this.state.activeUser.userEmail} 
                                  onChange={(e) => { this.setUserEmail(e.target.value) }} className="form-control" />
                            </FormGroup>
                            <FormGroup>
                              <Label>User Name</Label>
                              <input type="text" defaultValue={this.state.activeUser.userName} 
                                  onChange={(e) => { this.setUserName(e.target.value) }} className="form-control" />
                            </FormGroup>
                            <FormGroup>
                              <Label>User Surname</Label>
                              <input type="text" defaultValue={this.state.activeUser.userSurname} 
                                  onChange={(e) => { this.setUserSurname(e.target.value) }} className="form-control" />
                            </FormGroup>
                            <FormGroup>
                              <Label>User Role</Label>
                              <select className="form-control" defaultValue={this.state.activeUser.userGroup}
                                onChange={(e) => { this.setUserGroup(e.target.value) }}>
                                  <option value="">Select Role</option>
                                  <option value="admin">Admin</option>
                                  <option value="assessor">Assessor</option>
                              </select>
                            </FormGroup>
                            <FormGroup>
                              <Button className="btn-block" color="primary"
                                onClick={() => { this.saveUser() }}>Save</Button>
                            </FormGroup>
                          </Fragment>
                        )
                      }
                    </Col>
                  </Row>
                </Fragment>
              )
            }
              
            </Fragment>
          )
        }
        {
          this.state.loggedInUser && this.state.loggedInUser.userGroup === 'grader' && (
            <h1>You have no access to the admin panel</h1>
          )
        }
      </Container>
    );
  }
}

export default App;
